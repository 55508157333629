import { useNavbarContext } from 'contexts/NavbarContext';
import React, { useEffect, useRef } from 'react';
import scrollTo from 'utilities/scrollTo';
import { ListItem, List, ListWrapper } from './ImplementationNav.styled';

const ImplementationNav = ({ activeSection }) => {
   const { isMenuVisible, navbarHeight } = useNavbarContext();
   const listRef = useRef(null);
   const sections = [
      'start',
      'audyt',
      'potrzeby',
      'prototyp',
      'implementacja',
      'podsumowanie',
   ];

   useEffect(() => {
      const list = listRef.current;
      if (!list) {
         return;
      }
      const listItems = [...list.children];

      const currentItem = listItems.find(
         (el) => el.id === `${activeSection}-nav-item`,
      );

      if (currentItem) {
         list.scrollLeft = currentItem.offsetLeft - 150;
      }
   }, [activeSection]);

   return (
      <ListWrapper topOffset={isMenuVisible ? navbarHeight : 10}>
         <List ref={listRef}>
            {sections.map((section) => {
               return (
                  <ListItem
                     id={`${section}-nav-item`}
                     key={section}
                     className={activeSection === section ? 'active' : null}
                  >
                     <button onClick={() => scrollTo(section, 120)}>
                        {section}
                     </button>
                  </ListItem>
               );
            })}
         </List>
      </ListWrapper>
   );
};

export default ImplementationNav;
