import image from 'assets/images/implementation/image21.jpg';
import imageX2 from 'assets/images/implementation/image21@2x.jpg';
import React, { Fragment } from 'react';
import {
   Heading,
   ImageContainer,
   Paragraph,
   Row,
   SectionWrapper,
} from '../HistorySection.styled';

const NeedsSection = () => {
   const mainHeading = [
      <strong>Badania potrzeb</strong>,
      ' i architektura informacji',
   ];

   return (
      <SectionWrapper>
         <Heading className="history" id="potrzeby">
            {mainHeading.map((el, idx) => (
               <Fragment key={idx}>{el}</Fragment>
            ))}
         </Heading>
         <Paragraph>
            Badanie potrzeb w Grupie Azoty podsumować można jednynie tak, że
            oczekiwania co do sytemu były ogromne - zarówno ze strony przyszłych
            użytkowników, ich supervisorów, jak i kierownictwa najwyższego
            szczebla. Początek pracy nad systemem to zbudowanie solidnej
            architektury, czyli wymagania Kienta zamienić w rozwiązania, które
            rozwiążą jego problemy.
         </Paragraph>
         <Paragraph>
            Na bazie przeprowadzonego audytu powstaje architektura systemu,
            czyli diagram czynności i przypadków użycia. Po kilku uwagach ze
            strony Grupa Azoty zatwierdzona architektura ląduje na produkcji i
            zaczynamy proces jej zamiany w konkretne funkcjonalności i widoki
            makiety systemu.
         </Paragraph>
         ,
         <Row>
            <ImageContainer>
               <img src={image} srcSet={`${image} 1x, ${imageX2} 2x`} alt="" />
            </ImageContainer>
         </Row>
      </SectionWrapper>
   );
};

export default NeedsSection;
