import React, { Fragment } from 'react';
import { Heading, Paragraph, SectionWrapper } from '../HistorySection.styled';
import Video from '../Video';

const SummarySection = () => {
   const mainHeading = [<strong>Wnioski</strong>, ' po wdrożeniu'];

   return (
      <SectionWrapper>
         <Heading id="podsumowanie" className="history">
            {mainHeading.map((el, idx) => (
               <Fragment key={idx}>{el}</Fragment>
            ))}
         </Heading>
         <Paragraph>
            W ekspresowym tempie powstaje lekki, zwinny i elastyczny system do
            obsługi laboratoriów. Ogrom wiedzy, życzliwych informacji zwrotnych
            oraz nieoceniona możliwość przetestowania naszego LabControl w
            jednym w największych przedsiębiorstw na polskim rynku sprawia, że
            system jest jednym znajbardziej użytecznych rozwiązań na rynku niż
            większość systemów konkurencyjnych.
         </Paragraph>
         <Video />
      </SectionWrapper>
   );
};

export default SummarySection;
