import ekrany from 'assets/icons/implementation/ekrany_60.svg';
import lineUp from 'assets/icons/implementation/Path4.svg';
import lineDown from 'assets/icons/implementation/Path5.svg';
import rola from 'assets/icons/implementation/rola_60.svg';
import wersja from 'assets/icons/implementation/wersja_60.svg';
import React, { Fragment } from 'react';
import { Heading, Paragraph, SectionWrapper } from '../HistorySection.styled';
import {
   Icon,
   IconsContainer,
   IconsWrapper,
} from './ImplementationSection.styled';

const ImplementationSection = () => {
   const mainHeading = [<strong>Implementacja</strong>, ' produktu'];
   return (
      <SectionWrapper>
         <Heading className="history" id="implementacja">
            {mainHeading.map((el, idx) => (
               <Fragment key={idx}>{el}</Fragment>
            ))}
         </Heading>
         <Paragraph>
            Implementacja w trakcie akceleracji KPT ScaleUP sprowadza się do
            testowania oprogramowania w rzeczywistym środowisku, czyli w dwóch
            wybranych laboratoriach Grupy Azoty.
         </Paragraph>
         <IconsContainer>
            <img src={lineUp} alt="" className="line-up" />
            <IconsWrapper>
               <Icon>
                  <img src={rola} alt="" />
                  <p>
                     <strong>4 role</strong> użytkowników
                  </p>
               </Icon>
               <Icon>
                  <img src={wersja} alt="" />
                  <p>
                     Wersja <strong>desktop & tablet</strong>
                  </p>
               </Icon>
               <Icon>
                  <img src={ekrany} alt="" />
                  <p>
                     Ponad <strong>40 ekranów</strong>
                  </p>
               </Icon>
            </IconsWrapper>
            <img src={lineDown} alt="" className="line-down" />
         </IconsContainer>
      </SectionWrapper>
   );
};

export default ImplementationSection;
