import Container from 'components/shared/Container';
import React from 'react';
import {
   ChallengesAndEffects,
   Image,
   ImgContainer,
   ListItem,
   List,
   ListHeader,
} from './ChallengesSection.styled';
import image from 'assets/images/image17.png';

const ChallengesSection = () => {
   const challenges = [
      'Trudność w tworzeniu nowych szablonów zleceń bez pomocy zewnętrznej firmy IT',
      'Archaiczna forma prowadzenia ewidencji wyników utrudniająca codzienną pracę laboratoryjną',
      'Skomplikowany proces rozbudowy i rozwoju dotychczasowego systemu do nowych warunków i wyzwań stawianych przed laboratoriami',
      'Opór ludzki przed zmianą i nowymi technologiami',
   ];

   const effects = [
      'Dostarczyliśmy narzędzie, dzięki któremu pracownicy samodzielnie mogą tworzyć nowe formularze do pracy',
      'Umożliwienie prezentacji wyników historycznych dostępnych w momencie wprowadzania nowych',
      'Dostarczyliśmy system modułowy, który może być elastycznie modyfikowany przez użytkowników wewnątrz organizacji klienta',
      'Intuicyjna obsługa systemu sprawiła, że najbardziej oporni użytkownicy pracowali w nim bez specjalnego wyszkolenia',
   ];

   return (
      <>
         <Container>
            <ImgContainer>
               <Image src={image} />
            </ImgContainer>
         </Container>
         <Container>
            <ChallengesAndEffects>
               <List>
                  <ListHeader>Wyzwania</ListHeader>
                  {challenges.map((challenge, idx) => (
                     <ListItem key={idx}>{challenge}</ListItem>
                  ))}
               </List>
               <List>
                  <ListHeader>Efekty</ListHeader>
                  {effects.map((effect, idx) => (
                     <ListItem key={idx}>{effect}</ListItem>
                  ))}
               </List>
            </ChallengesAndEffects>
         </Container>
      </>
   );
};

export default ChallengesSection;
