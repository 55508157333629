import styled from 'styled-components';
import { breakpoints, colors } from 'styles/variables';

export const ImgContainer = styled.div`
   width: 100%;
   max-height: 420px;
   display: flex;
   background-color: ${colors.trialBg};
   margin-top: 90px;
   padding-left: 20px;
   border-radius: 12px;
   flex-direction: column;
   ${breakpoints.md`
      margin-top: 110px;
   `};
`;

export const Image = styled.img`
   display: block;
   max-width: 290px;
   max-height: 100%;
   margin-left: auto;
   margin-right: 0;
   margin: 0 auto;

   ${breakpoints.md`
      margin-right: 100px;
      max-width: initial;
   `};
`;

export const ChallengesAndEffects = styled.div`
   max-width: 960px;
   margin-top: 50px;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   ${breakpoints.md`
      margin-top: 100px;
      flex-direction: row;
   `}
`;

export const List = styled.ul`
   margin-top: 30px;
   padding: 0 20px;
   list-style-type: disc;
`;

export const ListHeader = styled.h2`
   font-family: Metropolis;
   font-weight: 700;
   font-size: 3rem;
`;

export const ListItem = styled.li`
   margin-left: 16px;
   font-size: 1.6rem;
   margin-top: 13px;
   line-height: 2.6rem;
`;
