import demoImage from 'assets/images/implementation/image19.jpg';
import demoImageX2 from 'assets/images/implementation/image19@2x.png';
import playbutton from 'assets/icons/play_24.svg';
import React, { useState } from 'react';
import { colors } from 'styles/variables';
import {
   CalendarGrid,
   CallendarWrapper,
   Description,
   Month,
   Months,
   Tile,
   Tiles,
} from './Calendar.styled';
import Modal from 'react-modal';
import Video from '../HistorySection/Video';

Modal.setAppElement('#___gatsby');

const Calendar = () => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   function closeModal() {
      setIsModalOpen(false);
   }
   const months = ['Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie'];

   return (
      <>
         <CallendarWrapper as="section">
            <h3>
               <strong>Jak budujemy</strong> produkt szyty na miarę
            </h3>
            <CalendarGrid>
               <Months>
                  {months.map((month) => (
                     <Month key={month}>{month}</Month>
                  ))}
               </Months>
               <Tiles>
                  <Tile bgColor={colors.lightBlue} className="research">
                     Research
                  </Tile>
                  <Tile bgColor={colors.lightGreen} className="ux">
                     UX
                  </Tile>
                  <Tile bgColor={colors.lightGreen} className="ui-design">
                     Ui Design
                  </Tile>
                  <Tile bgColor={colors.lightBlue} className="version">
                     4.0
                  </Tile>
                  <Tile bgColor={colors.lightGreen} className="ui">
                     UI
                  </Tile>
                  <Tile className="demo">
                     <img
                        src={demoImage}
                        srcSet={`${demoImage} 1x, ${demoImageX2} 2x`}
                        alt=""
                     />
                     <button onClick={() => setIsModalOpen(true)}>
                        <img src={playbutton} alt="" />
                     </button>
                  </Tile>
                  <Description className="research">
                     Audyt, badanie potrzeb, analiza konkurencji
                  </Description>
                  <Description className="ux">
                     Role użytkownika, architektura informacji
                  </Description>
                  <Description className="ui-design">
                     Prototyp, Przewodnik
                  </Description>
                  <Description className="version">Industry 4.0</Description>
                  <Description className="ui">Review</Description>
                  <Description className="demo">Demo Day</Description>
                  <Tile bgColor={colors.lightGreen} className="development">
                     Development
                  </Tile>
                  <Tile bgColor={colors.lightGreen} className="deploy">
                     Deploy
                  </Tile>
                  <Description className="development">
                     Web app development (desktop & tablet)
                  </Description>
                  <Description className="deploy">
                     Testing & deployment
                  </Description>
               </Tiles>
            </CalendarGrid>
         </CallendarWrapper>
         <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={{
               overlay: {
                  zIndex: 20,
                  background: `rgba(0,0,0,0.3)`,
               },
               content: {
                  background: 'none',
                  border: 'none',
                  top: '50%',
                  left: '50%',
                  width: '100%',
                  maxWidth: '960px',
                  right: 'auto',
                  bottom: 'auto',
                  transform: 'translate(-50%, -50%)',
               },
            }}
         >
            <Video />
         </Modal>
      </>
   );
};

export default Calendar;
