import React from 'react';
import {
   IconItemWrapper,
   IconWrapper,
   IconSpan,
   IconParagraph,
} from './HeaderIcon.styled';

const HeaderIcon = ({ icon, iconBg, iconHead, iconParagraph }) => {
   return (
      <IconItemWrapper>
         <IconWrapper bgColor={iconBg}>
            <img src={icon} alt="" />
         </IconWrapper>
         <IconParagraph>
            <IconSpan>{iconHead}</IconSpan>
            {iconParagraph}
         </IconParagraph>
      </IconItemWrapper>
   );
};

export default HeaderIcon;
