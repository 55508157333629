import styled from 'styled-components';
import { breakpoints } from 'styles/variables';

export const IconsContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-content: center;
   .line-up {
      display: none;
   }
   .line-down {
      display: none;
   }
   ${breakpoints.md`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-content: center;
   .line-up {
      grid-row: 1;
      display: block;
      justify-self: left;
      grid-column: 2/3;
    }
    .line-down {
      grid-row: 3;
      display: block;
      justify-self: right;
      grid-column: 1/2;
      align-self: left;
   }
   
   `}
`;

export const IconsWrapper = styled.div`
   grid-column: 1/3;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   text-align: center;
   ${breakpoints.md`
    grid-column: 1/3;
    flex-direction: row;
    justify-content: space-between;
  `}
`;
export const Icon = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 40px;
   ${breakpoints.md`
    margin-top: 0px;
    grid-column: 1/3;
    justify-content: space-between;
  `}
   img {
      max-height: 60px;
   }
`;
