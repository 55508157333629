import React, { useEffect, useState, useRef } from 'react';
import AuditSection from './AuditSection';
import { HistorySectionWrapper } from './HistorySection.styled';
import ImplementationNav from './ImplementationNav';
import ImplementationSection from './ImplementationSection';
import NeedsSection from './NeedsSection';
import PrototypeSection from './PrototypeSection';
import StartSection from './StartSection';
import SummarySection from './SummarySection';

const HistorySection = () => {
   const [activeSection, setActiveSection] = useState('');
   const observerRef = useRef(null);

   useEffect(() => {
      const historyElements = document.querySelectorAll('.history');
      observerRef.current = new IntersectionObserver(
         (entriesList) => {
            entriesList.forEach((entry) => {
               if (entry.isIntersecting) {
                  setActiveSection(entry.target.id);
               }
            });
         },
         {
            rootMargin: '0px 0px',
         },
      );
      historyElements.forEach((el) => {
         observerRef.current.observe(el);
      });
   }, []);
   return (
      <HistorySectionWrapper>
         <ImplementationNav activeSection={activeSection} />
         <StartSection />
         <AuditSection />
         <NeedsSection />
         <PrototypeSection />
         <ImplementationSection />
         <SummarySection />
      </HistorySectionWrapper>
   );
};

export default HistorySection;
