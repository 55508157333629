import Calendar from 'components/pages/implementation/Calendar';
import ChallengesSection from 'components/pages/implementation/ChalllengesSection';
import HeaderIcons from 'components/pages/implementation/HeaderIcons';
import HistorySection from 'components/pages/implementation/HistorySection';
import PageHeader from 'components/shared/PageHeader';
import SEO from 'components/shared/SEO';
import React from 'react';

const Implementation = () => {
   const heading = [
      'Jak udało nam się stworzyć LabControl podczas Akceleracji KPT ScaleUp z ',
      <strong>Grupą Azoty</strong>,
   ];
   return (
      <>
         <SEO title="Historia Wdrożenia" />
         <PageHeader headerText={heading}>
            <HeaderIcons />
         </PageHeader>
         <ChallengesSection />
         <Calendar />
         <HistorySection />
      </>
   );
};

export default Implementation;
