import React from 'react';
import calendar from 'assets/icons/implementation/calendar_60.svg';
import lab from 'assets/icons/implementation/lab_60.svg';
import logo_azoty from 'assets/icons/implementation/logo_azoty.svg';
import { colors } from 'styles/variables';
import HeaderIcon from './HeaderIcon';
import { HeaderIconList } from './HeaderIcons.styled';

const HeaderIcons = () => {
   const icons = [
      {
         iconUrl: logo_azoty,
         iconBg: colors.lightViolet,
         iconHead: 'Odbiorca technologii',
         iconParagraph: 'Grupa Azoty',
      },
      {
         iconUrl: lab,
         iconBg: colors.lightGreen,
         iconHead: 'Branża',
         iconParagraph: 'Laboratoryjna',
      },
      {
         iconUrl: calendar,
         iconBg: colors.lightBlue,
         iconHead: 'Czas realizacji',
         iconParagraph: '03.2020 - 08.2020',
      },
   ];

   return (
      <HeaderIconList>
         {icons.map(({ iconUrl, iconBg, iconHead, iconParagraph }, idx) => (
            <HeaderIcon
               key={idx}
               icon={iconUrl}
               iconBg={iconBg}
               iconHead={iconHead}
               iconParagraph={iconParagraph}
            />
         ))}
      </HeaderIconList>
   );
};

export default HeaderIcons;
