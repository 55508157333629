import styled from 'styled-components';

export const CallendarWrapper = styled.div`
   max-width: 960px;
   padding: 0 15px;
   margin: 100px auto;
`;

export const CalendarGrid = styled.div`
   margin-top: 60px;
   display: grid;
   grid-template-rows: repeat(5, 1fr);
   grid-template-columns: repeat(6, 150px);
   font-size: 2rem;
   line-height: 1;
   font-weight: 600;
   overflow-x: auto;
`;

export const Months = styled.div`
   grid-row: 1/4;
   display: grid;
   grid-column: 1/7;
   grid-template-columns: repeat(6, auto);
`;

export const Month = styled.div`
   padding-left: 12px;
   border-right: 1px solid #e4efff;
   :first-of-type {
      padding-left: 0;
   }
   :last-of-type {
      border-right: none;
   }
`;

export const Tiles = styled.div`
   grid-row: 2/6;
   grid-template-columns: repeat(24, 1fr);
   grid-column: 1/7;
   gap: 8px;
   position: relative;
   display: grid;
   .research {
      grid-column: 1/4;
   }
   .ux {
      grid-column: 4/8;
   }
   .ui-design {
      grid-column: 8/15;
   }
   .version {
      padding-left: 0;
      justify-content: center;
      grid-column: 17/18;
   }
   p.version {
      grid-column: 17/19;
   }
   .ui {
      grid-column: 21/23;
   }
   .demo {
      position: relative;
      padding: 0;
      cursor: pointer;
      grid-column: 23/25;
      overflow: hidden;
      :hover button {
         transform: translate(-50%, -50%) scale(1.2);
      }
      button {
         cursor: pointer;
         border: none;
         background: none;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         position: absolute;
         transition: transform 200ms ease;
      }
   }
   .development {
      grid-column: 9/23;
   }
   .deploy {
      grid-column: 23/25;
      justify-content: center;
      padding: 0;
   }
   div.development,
   div.deploy {
      margin-top: -40px;
   }
`;

export const Tile = styled.div`
   height: 63px;
   padding-left: 12px;
   align-content: center;
   background-color: silver;
   font-size: 1.6rem;
   border-radius: 12px;
   display: grid;
   background-color: ${(props) => props.bgColor};
`;

export const Description = styled.p`
   font-size: 1.3rem;
   line-height: 1.5rem;
   margin: 0;
   margin-bottom: 25px;
   font-weight: 400;
`;
