import styled from 'styled-components';
import { breakpoints } from 'styles/variables';

export const VideoContainer = styled.div`
   cursor: pointer;
   position: relative;
   padding-top: 56.25%;

   :hover button {
      transform: translate(-50%, -50%) scale(1.2);
   }
   .react-player {
      position: absolute;
      top: 0;
      left: 0;
   }
   .play-button {
      transition: transform 200ms ease;
      pointer-events: none;
      border: none;
      background: none;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      height: 70px;
      img {
         pointer-events: none;
         width: 100%;
         height: 100%;
      }
      ${breakpoints.lg`
         width: 120px;
         height: 120px;
      `}
   }
`;
