import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { VideoContainer } from './Video.styled';
import image from 'assets/images/implementation/image23.jpg';
import icon from 'assets/icons/implementation/play_152.svg';
import video from 'assets/video/Labcontrol.mp4';

const Video = () => {
   const [isPlaying, setIsPlaying] = useState(true);
   return (
      <VideoContainer>
         <ReactPlayer
            className="react-player"
            width="100%"
            height="100%"
            playIcon={
               <button className="play-button" title="play video">
                  <img src={icon} alt="play button" />
               </button>
            }
            onPause={() => setIsPlaying(false)}
            onPlay={() => setIsPlaying(true)}
            playing
            controls
            light={image}
            url={video}
         />
         {!isPlaying && (
            <button className="play-button">
               <img src={icon} alt="play button" />
            </button>
         )}
      </VideoContainer>
   );
};

export default Video;
