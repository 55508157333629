import styled from 'styled-components';
import { breakpoints } from 'styles/variables';

export const HeaderIconList = styled.ul`
   margin-top: 60px;
   padding: 0;
   justify-content: space-around;
   ${breakpoints.lg`
      padding: 0;
      display: flex;
      justify-content: space-around;
   `}
`;
