import React from 'react';
import { Fragment } from 'react';
import {
   SectionWrapper,
   Row,
   ImageContainer,
   DescriptionContainer,
   Heading,
   SubHeading,
   Description,
   Paragraph,
} from '../HistorySection.styled';
import image from 'assets/images/implementation/image18.jpg';
import imageX2 from 'assets/images/implementation/image18@2x.png';

const StartSection = () => {
   const mainHeading = [
      <strong>Poczatki współpracy</strong>,
      ' z KPT ScaleUp i Grupą Azoty',
   ];

   const additionalDescriptionHeading = ['KPT', <strong> ScaleUp</strong>];

   return (
      <SectionWrapper>
         <Heading id="start" className="history">
            {mainHeading.map((el, idx) => (
               <Fragment key={idx}>{el}</Fragment>
            ))}
         </Heading>
         <Paragraph>
            Od 2017 roku budujemy systemy IT, które rozwiązują realne problemy
            wydajności pracy w przedsiębiorstwach. W 2019 podjęliśmy decyzję o
            rozpoczęciu produkcji systemu, w którym zawrzemy wszystkie
            dotychczasowe doświadczenia i wymagania rynku B2B.
         </Paragraph>
         <Row reverseRow={true}>
            <ImageContainer withRadius={true}>
               <img src={image} srcSet={`${image} 1x, ${imageX2} 2x`} alt="" />
            </ImageContainer>

            <DescriptionContainer reverseRow={true}>
               <SubHeading>
                  {additionalDescriptionHeading.map((text, i) => (
                     <Fragment key={i}>{text}</Fragment>
                  ))}
               </SubHeading>

               <Description>
                  <Paragraph>
                     Z początkiem 2020 roku nasz plan wchodzi w fazę wykonawczą.
                     W marcu podpisujemy umowę z Krakowskim Parkiem
                     Technologicznym i zaczynamy trwającą 6 miesięcy przygodę,
                     czyli proces akceleracji LabControl z odbiorcą technologii
                     - Grupą Azoty w programie KPT ScaleUP.
                  </Paragraph>
                  <Paragraph>
                     Pod okiem praktyków i profesjonalistów z Działu Badań i
                     Analiz Grupa Azoty powstaje skalowalny, uniwersalny i
                     elastyczny system do obsługi laboratoriów.
                  </Paragraph>
               </Description>
            </DescriptionContainer>
         </Row>
      </SectionWrapper>
   );
};

export default StartSection;
