import image from 'assets/images/implementation/image22.jpg';
import imageX2 from 'assets/images/implementation/image22@2x.jpg';
import React, { Fragment } from 'react';
import {
   Heading,
   ImageContainer,
   Paragraph,
   Row,
   SectionWrapper,
} from '../HistorySection.styled';

const PrototypeSection = () => {
   const mainHeading = [
      <strong>Klikalny prototyp </strong>,
      ' i kilka iteracji',
   ];

   return (
      <SectionWrapper>
         <Heading id="prototyp" className="history">
            {mainHeading.map((el, idx) => (
               <Fragment key={idx}>{el}</Fragment>
            ))}
         </Heading>
         <Paragraph>
            Po kilku tygodniach wytężonego projektowania wszystkich widoków
            statycznych oraz dynamicznego flow procesów zaplanowanych w
            aplikacji następuje prezentacja prototypu Klientowi. Po kilku
            iteracjach z przyszłymi odbiorcami wprowadzamy niezbędne zmiany i
            zaczynamy etap developmentu.
         </Paragraph>
         <Row>
            <ImageContainer>
               <img src={image} srcSet={`${image} 1x, ${imageX2} 2x`} alt="" />
            </ImageContainer>
         </Row>
      </SectionWrapper>
   );
};

export default PrototypeSection;
