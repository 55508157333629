import styled from 'styled-components';

export const IconItemWrapper = styled.li``;

export const IconWrapper = styled.div`
   margin: 0 auto;
   width: 110px;
   height: 110px;
   display: flex;
   align-content: center;
   justify-content: center;
   background: ${(props) => props.bgColor};
   border-radius: 100%;
`;

export const IconSpan = styled.span`
   display: block;
   font-size: 1.6rem;
   font-weight: 700;
   line-height: 2.6rem;
`;

export const IconParagraph = styled.p`
   font-size: 2.2rem;
   line-height: 3rem;
`;
