import styled from 'styled-components';
import { colors, transitions } from 'styles/variables';

export const ListWrapper = styled.nav`
   position: sticky;
   top: ${({ topOffset }) => `${topOffset}px`};
   transition: top ${transitions.ease};
   z-index: 1;
`;

export const List = styled.ul`
   overflow-x: auto;
   scroll-behavior: smooth;
   display: flex;
   max-width: 960px;
`;

export const ListItem = styled.li`
   flex-grow: 1;
   font-size: 1.4rem;
   font-weight: 500;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   border: 1px solid ${colors.brand};
   background: #fff;
   border-right: none;
   transition: background-color ${transitions.ease};
   &.active {
      background: ${colors.brand};
      color: white;
   }

   &:first-of-type {
      border-right: none;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
   }
   &:last-of-type {
      border-left: none;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      border: 1px solid ${colors.brand};
   }
   button {
      color: inherit;
      text-transform: capitalize;
      cursor: pointer;
      font-weight: 500;
      padding: 15px 22px;
      border: none;
      background: none;
   }
   &:hover {
      background: ${colors.brand};
      color: white;
   }
`;
