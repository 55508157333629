import image from 'assets/images/implementation/image20.jpg';
import imageX2 from 'assets/images/implementation/image20@2x.jpg';
import React, { Fragment } from 'react';
import {
   Description,
   DescriptionContainer,
   Heading,
   ImageContainer,
   Paragraph,
   Row,
   SectionWrapper,
   SubHeading,
} from '../HistorySection.styled';

const AuditSection = () => {
   const mainHeading = [<strong>Audyt</strong>, ' trudne początki'];
   const additionalDescriptionHeading = [
      <strong>Ustalenia. </strong>,
      'System ma spełniać 3 podstawowe wymagania:',
   ];
   return (
      <SectionWrapper>
         <Heading className="history" id="audyt">
            {mainHeading.map((el, idx) => (
               <Fragment key={idx}>{el}</Fragment>
            ))}
         </Heading>
         <Paragraph>
            Oficjalne otwarcie KPT Scale Up w pierwszym tygodniu marca daje nam
            zielone światło do tego, na co czekaliśmy najbardziej - wizyty u
            Odbiorcy Technologii, czyli audyt w Tarnowie, w głównej siedzibie
            Centrum Badań i Analiz Grupy Azoty. Trzy dni wytężonej uwagi,
            koncetracji, setki pytań i wiele merytorycznych sugestii, które
            miały za kilka miesięcy zostać odzwierciedlone w systemie
            LabControl.
         </Paragraph>
         <Row>
            <ImageContainer withRadius={true}>
               <img src={image} srcSet={`${image} 1x, ${imageX2} 2x`} alt="" />
            </ImageContainer>

            <DescriptionContainer>
               <SubHeading>
                  {additionalDescriptionHeading.map((text, i) => (
                     <Fragment key={i}>{text}</Fragment>
                  ))}
               </SubHeading>

               <Description>
                  <ul>
                     <li>być szybki i łatwy w obsłudze</li>
                     <li>elastyczny w kontekście samodzielnej rozbudowy</li>
                     <li>
                        uporządkować i zamienić, gdzieniegdzie funkcjonujące
                        nadal, papierowe formularze
                     </li>
                  </ul>
               </Description>
            </DescriptionContainer>
         </Row>
      </SectionWrapper>
   );
};

export default AuditSection;
