import styled from 'styled-components';
import { breakpoints } from 'variables';

export const HistorySectionWrapper = styled.div`
   max-width: 960px;
   padding: 0 15px;
   margin: 0 auto;
`;

export const SectionWrapper = styled.section`
   margin-top: 50px;
   p {
      line-height: 3rem;
   }
   ${breakpoints.lg`
      margin-top: 120px;
  `}
`;

export const Heading = styled.h2`
   font-size: 3rem;
   line-height: 5rem;
`;

export const SubHeading = styled.h3`
   color: #000000;
   font-family: Metropolis;
   font-size: 2.2rem;
`;

export const Paragraph = styled.p`
   margin-bottom: 30px;
   font-size: 1.6rem;
   line-height: 3rem;
`;

export const Row = styled.div`
   width: 100%;
   display: flex;
   flex-direction: column;
   margin-top: 70px;

   ${breakpoints.lg`

      margin-top: 90px;
      flex-direction: ${({ reverseRow }) =>
         reverseRow ? 'row-reverse' : 'row'};

   `}
`;

export const ImageContainer = styled.div`
   border-radius: ${(props) => (props.withRadius ? '12px' : '0px')};
   width: 100%;
   display: flex;
   justify-content: center;
   > img {
      max-height: 100%;
      border-radius: ${(props) => (props.withRadius ? '12px' : '0px')};
      max-width: 100%;
   }
`;

export const DescriptionContainer = styled.section`
   align-self: center;
   flex: 100%;
   margin-top: 50px;
   ${breakpoints.lg`
      text-align: left;
      ${({ reverseRow }) =>
         reverseRow ? 'margin-right: 100px' : 'margin-left: 100px'};
      margin-top: 15px;
   `};
`;

export const Header = styled.h2`
   font-size: 2rem;
   font-weight: 300;
   line-height: 28px;

   strong {
      font-weight: 700;
   }

   ${breakpoints.lg`
      font-size: 3rem;
      line-height: 36px;
   `}
`;

export const Description = styled.div`
   line-height: 24px;
   margin: 10px 0 15px;
   font-weight: 300;
   p {
      font-size: 1.6rem;
      line-height: 3rem;
   }
   ul {
      padding-left: 15px;
      line-height: 3rem;
      font-size: 1.6rem;
      li {
         margin-bottom: 20px;
         list-style-type: disc;
      }
   }

   ${breakpoints.lg`
      margin: 30px 0 20px;
      line-height: 28px;
   `}
`;
